<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
  >
    {{ message }}
    <v-btn
      text
      color="accent"
      @click.native="show = false"
    />
  </v-snackbar>
</template>

<script>
export default {
     name:"Snackbar",
  data() {
    return {
      show: false,
      color: "",
      message: "",
      timeout:15000 // milliseconds. 15000 milliseconds = 15 seconds.
    };
  },
  created: function () {
    this.$store.watch(
      (state) => state.snackbar.message,
      () => {
        const msg = this.$store.state.snackbar.message;
        if (msg !== "") {
          this.show = true;
          this.message = this.$store.state.snackbar.message;
          this.color = this.$store.state.snackbar.color;
          this.$store.commit("snackbar/setSnack", "");
        }
      }
    );
  },
};
</script>
